function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

function generateRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

function getRandomInteger() {
    // return a random integer between 1-100
    return Math.floor(Math.random() * 100) + 1;
}

function randomizeBackground() {
    const customImages = localStorage.getItem('customImages') || '';
    let currentImageIndex = localStorage.getItem('currentImageIndex') || -1;

    if ( customImages ) {
        let newImage = customImages.split(',').filter(w => w !== '');

        currentImageIndex++;
        
        if ( ! newImage[currentImageIndex] ) {
            currentImageIndex = 0;
        }

        newImage = newImage[ currentImageIndex ];

        if ( newImage ) {
            newImage = process.env.REACT_APP_API_BASE_URL + '/uploads/' + newImage;

            // set newImage as body background
            document.body.style.backgroundImage = `url(${newImage})`;

            // set background to cover
            document.body.style.backgroundSize = 'cover';

            // set background position
            document.body.style.backgroundPosition = 'center center';

            // set background to not repeat
            document.body.style.backgroundRepeat = 'no-repeat';
        }

        localStorage.setItem('currentImageIndex', currentImageIndex);
    }
}

const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    variableWidth: false, // To make each slide take the width of its contents
};

const alphabetLetters = [
    { letter: 'A', phonic: 'Ah, ah, ah, ah is for apple.', image: '/images/a.jpg' },
    { letter: 'B', phonic: 'Buh, buh, buh, buh is for ball.', image: '/images/b.jpg' },
    { letter: 'C', phonic: 'Kuh, kuh, kuh, kuh is for cat.', image: '/images/c.jpg' },
    { letter: 'D', phonic: 'Duh, duh, duh, duh is for dog.', image: '/images/d.jpg' },
    { letter: 'E', phonic: 'Eh, eh, eh, eh is for elephant.', image: '/images/e.jpg' },
    { letter: 'F', phonic: 'Fff, fff, fff, fff is for fish.', image: '/images/f.jpg' },
    { letter: 'G', phonic: 'Guh, guh, guh, guh is for goat.', image: '/images/g.jpg' },
    { letter: 'H', phonic: 'Huh, huh, huh, huh is for hat.', image: '/images/h.jpg' },
    { letter: 'I', phonic: 'Ih, ih, ih, ih is for igloo.', image: '/images/i.jpg' },
    { letter: 'J', phonic: 'Juh, juh, juh, juh is for jug.', image: '/images/j.jpg' },
    { letter: 'K', phonic: 'Kuh, kuh, kuh, kuh is for kite.', image: '/images/k.jpg' },
    { letter: 'L', phonic: 'Lll, lll, lll, lll is for lion.', image: '/images/l.jpg' },
    { letter: 'M', phonic: 'Mmm, mmm, mmm, mmm is for moon.', image: '/images/m.jpg' },
    { letter: 'N', phonic: 'Nnn, nnn, nnn, nnn is for nest.', image: '/images/n.jpg' },
    { letter: 'O', phonic: 'Ahh, ahh, ahh, ahh is for octopus.', image: '/images/o.jpg' },
    { letter: 'P', phonic: 'Puh, puh, puh, puh is for pig.', image: '/images/p.jpg' },
    { letter: 'Q', phonic: 'Kwuh, kwuh, kwuh, kwuh is for queen.', image: '/images/q.jpg' },
    { letter: 'R', phonic: 'Rrr, rrr, rrr, rrr is for rabbit.', image: '/images/r.jpg' },
    { letter: 'S', phonic: 'Sss, sss, sss, sss is for snake.', image: '/images/s.jpg' },
    { letter: 'T', phonic: 'Tuh, tuh, tuh, tuh is for tiger.', image: '/images/t.jpg' },
    { letter: 'U', phonic: 'Uh, uh, uh, uh is for umbrella.', image: '/images/u.jpg' },
    { letter: 'V', phonic: 'Vvv, vvv, vvv, vvv is for violin.', image: '/images/v.jpg' },
    { letter: 'W', phonic: 'Wuh, wuh, wuh, wuh is for whale.', image: '/images/w.jpg' },
    { letter: 'X', phonic: 'Ks, ks, ks, ks is for x-ray.', image: '/images/x.jpg' },
    { letter: 'Y', phonic: 'Yuh, yuh, yuh, yuh is for yo-yo.', image: '/images/y.jpg' },
    { letter: 'Z', phonic: 'Zzz, zzz, zzz, zzz is for zebra.', image: '/images/z.jpg' },
];

export { shuffleArray, generateRandomColor, randomizeBackground, sliderSettings, alphabetLetters, getRandomInteger };