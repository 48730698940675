import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import Words from './components/Words';
import Letters from './components/Letters';
import Settings from './components/Settings';
import Rewards from './components/Rewards';
import './App.css';

function App() {

  useEffect(() => {
    const fontSize = localStorage.getItem('fontSize');
    const textColor = localStorage.getItem('textColor');

    if (fontSize) {
      document.documentElement.style.fontSize = ( fontSize == 'small' ? '12px' : ( fontSize == 'large' ? '20px' : '16px' ) );
    }

    if (textColor) {
      document.body.style.color = textColor;
      document.body.style.setProperty('--base-color', textColor);
    }
  }, []);

  return (
    <BrowserRouter>
   
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/words" element={<Words />} />
        <Route path="/letters" element={<Letters />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/rewards" element={<Rewards />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;