const Words = [
    { word: 'it', sound: '/sounds/words/it.mp3', image: '/images/a.jpg' },
    { word: 'tin', sound: '/sounds/words/tin.mp3', image: '/images/b.jpg' },
    { word: 'pat', sound: '/sounds/words/pat.mp3', image: '/images/c.jpg' },
    { word: 'nip', sound: '/sounds/words/nip.mp3', image: '/images/d.jpg' },
    { word: 'sat', sound: '/sounds/words/sat.mp3', image: '/images/e.jpg' },

    { word: 'as', sound: '/sounds/words/as.mp3', image: '/images/f.jpg' },
    { word: 'pan', sound: '/sounds/words/pan.mp3', image: '/images/g.jpg' },
    { word: 'sit', sound: '/sounds/words/sit.mp3', image: '/images/h.jpg' },
    { word: 'tip', sound: '/sounds/words/tip.mp3', image: '/images/i.jpg' },
    { word: 'ant', sound: '/sounds/words/ant.mp3', image: '/images/j.jpg' },

    { word: 'pest', sound: '/sounds/words/k.mp3', image: '/images/k.jpg' },
    { word: 'tap', sound: '/sounds/words/l.mp3', image: '/images/l.jpg' },
    { word: 'pin', sound: '/sounds/words/m.mp3', image: '/images/m.jpg' },
    { word: 'is', sound: '/sounds/words/n.mp3', image: '/images/n.jpg' },
    { word: 'pit', sound: '/sounds/words/o.mp3', image: '/images/o.jpg' },

    { word: 'hip', sound: '/sounds/words/p.mp3', image: '/images/p.jpg' },
    { word: 'pet', sound: '/sounds/words/q.mp3', image: '/images/q.jpg' },
    { word: 'mat', sound: '/sounds/words/r.mp3', image: '/images/r.jpg' },
    { word: 'ran', sound: '/sounds/words/s.mp3', image: '/images/s.jpg' },
    { word: 'dip', sound: '/sounds/words/t.mp3', image: '/images/t.jpg' },

    { word: 'snap', sound: '/sounds/words/u.mp3', image: '/images/u.jpg' },
    { word: 'in', sound: '/sounds/words/v.mp3', image: '/images/v.jpg' },
    { word: 'man', sound: '/sounds/words/w.mp3', image: '/images/w.jpg' },
    { word: 'red', sound: '/sounds/words/x.mp3', image: '/images/x.jpg' },
    { word: 'hit', sound: '/sounds/words/y.mp3', image: '/images/y.jpg' },

    { word: 'ham', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },
    { word: 'map', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },
    { word: 'rat', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },
    { word: 'his', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },
    { word: 'rip', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },

    { word: 'cat', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },
    { word: 'hen', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },
    { word: 'can', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },
    { word: 'hat', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },
    { word: 'neck', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },

    { word: 'camp', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },
    { word: 'kick', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },
    { word: 'met', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },
    { word: 'pen', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },
    { word: 'deck', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },

    { word: 'kist', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },
    { word: 'mist', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },
    { word: 'best', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },
    { word: 'hiss', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },
    { word: 'deck', sound: '/sounds/words/z.mp3', image: '/images/z.jpg' },

];

export { Words };