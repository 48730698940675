import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import settingsIcon from '../components/images/settingsIcon.png'
import settingsTitle from '../components/images/settings-title.svg'
import closeIcon from '../components/images/close-icon.svg'
import Select from 'react-select'
import axios from 'axios';


import CustomPopup from "./Popup/CustomPopup";

function Settings() {

    let childName = localStorage.getItem('childName');
    const [customWords, setCustomWords] = useState(localStorage.getItem('customWords') || '');
    
    const [customImages, setCustomImages] = useState(localStorage.getItem('customImages') || '');

    const [showNamePopup, setShowNamePopup] = useState(true);
    const [name, setName] = useState(childName);
    const [showWordManagement, setShowWordManagement] = useState(false);
    const [showBGManagement, setShowBGManagement] = useState(false );
    
    const [showAccessibilityPopup, setShowAccessibilityPopup] = useState(true);
    const [fontSize, setFontSize] = useState(localStorage.getItem('fontSize') || 'medium');
    const [rewardType, setRewardType] = useState(localStorage.getItem('rewardType') || 'toys');
    const [quizInterval, setQuizInterval] = useState(localStorage.getItem('quizInterval') || '5');
    const [textColor, setTextColor] = useState(localStorage.getItem('textColor') || '#000');
    const [disableGradients, setDisableGradients] = useState(localStorage.getItem('disableGradients') || '0');

    const [newWord, setNewWord] = useState('');
    const [file, setFile] = useState(null);

    const fontOptions = [
        {
            value: 'small',
            label: 'Small',
        },
        {
            value: 'medium',
            label: 'Medium',
        },
        {
            value: 'large',
            label: 'Large',
        }
    ];

    const rewardsOptions = [
        {
            value: 'toys',
            label: 'Toys',
        },
        {
            value: 'girls',
            label: 'Girls',
        },
        {
            value: 'cars',
            label: 'Cars',
        },
        {
            value: 'dinos',
            label: 'Dinosaurs',
        }
    ];

    const quizIntervalOptions = [
        {
            value: '3',
            label: '3 Items',
        },
        {
            value: '4',
            label: '4 Items',
        },
        {
            value: '5',
            label: '5 Items',
        },
        {
            value: '6',
            label: '6 Items',
        },
        {
            value: '7',
            label: '7 Items',
        },
        {
            value: '8',
            label: '8 Items',
        },
    ];

    function saveWord() {
        if (!customWords) {
            setCustomWords(newWord);
        } else {
            setCustomWords(prevWords => prevWords + ',' + newWord);
        }
        setNewWord('');
    }

    function saveFile() {
        // upload the file
        const formData = new FormData();
        formData.append('file', file);

        // get url from .env 
        const url = process.env.REACT_APP_API_BASE_URL;

        axios.post( `${url}/api/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        } )
        .then( response => {
            // save the URL in localStorage
            setCustomImages(prevImages => prevImages + ',' + response.data);

            document.getElementById('customImageFile').value = '';
            setFile(null);
        } )
        .catch( error => {
        } );
    }

    function removeWord(word) {
        let newWords = customWords.split(',').filter(w => w !== word);
        setCustomWords(newWords.join(','));
    }

    function removeImage(image) {
        let newImages = customImages.split(',').filter(w => w !== image);
        setCustomImages(newImages.join(','));
    }

    // use effect on customWords
    useEffect(() => {
        localStorage.setItem( 'customWords', customWords );
    }, [customWords]);

    useEffect(() => {
        localStorage.setItem( 'fontSize', fontSize );
        document.documentElement.style.fontSize = ( fontSize == 'small' ? '12px' : ( fontSize == 'large' ? '20px' : '16px' ) );
    }, [fontSize]);

    useEffect(() => {
        localStorage.setItem( 'rewardType', rewardType );
    }, [rewardType]);

    useEffect(() => {
        localStorage.setItem( 'quizInterval', quizInterval );
    }, [quizInterval]);

    useEffect(() => {
        localStorage.setItem( 'textColor', textColor );
        document.body.style.color = textColor;
        document.body.style.setProperty('--base-color', textColor);
    }, [textColor]);

    useEffect(() => {
        localStorage.setItem( 'disableGradients', disableGradients ? '1' : '0' );
    }, [disableGradients]);

    useEffect(() => {
        localStorage.setItem( 'customImages', customImages );
    }, [customImages]);
    
    return (
        <div>
            <Sidebar
                active="settings"
            />

            <CustomPopup
                trigger={showWordManagement}
                className="words-popup"
                closeIcon={closeIcon}
                setTrigger={() => {
                    setShowWordManagement(false );
                }}
            >

                <div className="add-word-wrapper">
                    <h3 className="popup-title">Add Your Own Words</h3>

                    {customWords && <div className='total-words-added'>You have added {customWords.split(',').filter(x => x).length} words</div>}

                    <div className="current-words-list">
                        {customWords && customWords.split(',').filter(x => x).map((word, index) => <div className="word" key={index}>{word}<span onClick={() => removeWord(word)}></span></div>)}
                        {!customWords && <div className='no-words-added'>You have not added a custom words list.</div>}
                    </div>

                    <div className="add-word-form">
                        <input placeholder="Type a word here" name="setNewWord" value={newWord} onChange={(e) => setNewWord(e.target.value)} />
                        <button className='save-btn blue-bg-btn' onClick={() => saveWord() }>Add</button>
                    </div>
                </div>

            </CustomPopup>

            <CustomPopup
                trigger={showBGManagement}
                className="words-popup"
                closeIcon={closeIcon}
                setTrigger={() => {
                    setShowBGManagement(false );
                }}
            >

                <div className="background-management-wrapper">
                    <h3 className="popup-title">Background Management</h3>

                    {customImages && <div className='total-words-added'>You have added {customImages.split(',').filter(x => x).length} images</div>}

                    <div className="current-backgrounds-list">
                        {customImages && customImages.split(',').filter(x => x).map((image, index) => <div className="custom-background-image" key={index} style={{ backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL+'/uploads/'+image})` }}><span onClick={() => removeImage(image)}>x</span></div>)}
                        {!customImages && <div className='no-words-added'>You have not added a custom image for background.</div>}
                    </div>
                    <div className="add-word-form">
                        <input type="file" placeholder="Type a word here" id="customImageFile" name="setFile" onChange={(e) => setFile(e.target.files[0])} />
                        <button className='save-btn blue-bg-btn' onClick={() => saveFile() }>Add</button>
                    </div>
                </div>

            </CustomPopup>

            <CustomPopup
                trigger={!showNamePopup}
                className="add-name-popup"
                closeIcon={closeIcon}
                setTrigger={() => {
                    setShowNamePopup(true);
                }}
            >

                <h3 className="popup-title">Add Your Child's Name</h3>

                <div className="form-field">
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" name="name" onChange={(e) => setName(e.target.value)} defaultValue={name} placeholder="Enter name here" />
                </div>

                <div className='text-center'>
                    <button className="blue-bg-btn" onClick={() => {localStorage.setItem('childName', name); setShowNamePopup(true)}}>
                        <span>Save</span>
                    </button>
                </div>

            </CustomPopup>

            <CustomPopup
                trigger={!showAccessibilityPopup}
                className="accessibility-popup"
                setTrigger={() => {
                    setShowAccessibilityPopup(true);
                }}
            >
                <div className="accessibility-popup-inner">
                    <h3 className='popup-title'>Accessibility</h3>

                    <div className='form-field'>
                        <label htmlFor='font-size'>Font Size</label>
                        <Select
                            options={fontOptions}
                            className="font-size-select"
                            value={fontOptions.find(obj => obj.value === fontSize)}
                            onChange={(value) => setFontSize(value.value)}
                        />
                    </div>

                    <div className='form-field'>
                        <label htmlFor='font-size'>Rewards Stickers Type</label>
                        <Select
                            options={rewardsOptions}
                            className="font-size-select"
                            value={rewardsOptions.find(obj => obj.value === rewardType)}
                            onChange={(value) => setRewardType(value.value)}
                        />
                    </div>

                    <div className='form-field'>
                        <label htmlFor='font-size'>Quiz After Every:</label>
                        <Select
                            options={quizIntervalOptions}
                            className="font-size-select"
                            value={quizIntervalOptions.find(obj => obj.value === quizInterval)}
                            onChange={(value) => setQuizInterval(value.value)}
                        />
                    </div>

                    <div className='form-field'>
                        <label class='form-field checkbox-field'>
                            <span>Disable Gradient</span>
                            <input
                                type="checkbox"
                                id="disable-gradient"
                                name="disable-gradient"
                                onChange={(e) => setDisableGradients(e.target.checked)}
                                checked={disableGradients}
                                defaultValue={disableGradients} />
                            <span></span>
                        </label>
                    </div>

                    <div className='form-field'>
                        <label htmlFor='text-color'>Text Color</label>
                        <input type="color" id="text-color" name="text-color" onChange={(e) => setTextColor(e.target.value)} defaultValue={textColor} />
                    </div>
                </div>

            </CustomPopup>

            <div className="content">
                <div className="settings-wrapper">

                    { <>
                        <div className="settings-inner">

                            <div className='settings-page-title'>
                                <img src={settingsTitle} alt="Settings" />
                            </div>

                            <div className="settings-sections">
                                <div className="settings-section" onClick={() => setShowWordManagement(true)}>
                                    <h3>Add Your Own Words</h3>
                                    <p>Add words your child needs to learn</p>
                                </div>
                                <div className="settings-section" onClick={() => setShowNamePopup(false)}>
                                    <h3>Add Your Child's Name</h3>
                                    <p>Personalize the game for your child</p>
                                </div>
                                <div className="settings-section" onClick={() => setShowBGManagement(true)}>
                                    <h3>Add Your Own Backgrounds</h3>
                                    <p>Add your child's favourite characters or scenes as backgrounds</p>
                                </div>
                                <div className="settings-section" onClick={() => setShowAccessibilityPopup(false)}>
                                    <h3>Accessibility</h3>
                                    <p>Change font sizes and colors</p>
                                </div>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </div>
    );
}

export default Settings