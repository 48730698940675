import React from 'react';
import { Link } from 'react-router-dom';
import homeIcon from '../components/images/home-icon.svg';
import lettersBlock from '../components/images/alphabets-icon.png';
import bookIcon from '../components/images/words-icon.svg'
import settingsIcon from '../components/images/settings-icon.svg'
import rewardsIcon from '../components/images/rewards-btn.svg'

function Sidebar( props ) {
    const childName = localStorage.getItem('childName');

    return (
        <>

            {childName && <>
                <div className="current-user-name">{childName}</div>
            </>}

            <Link to="/rewards"  className={'rewards-btn ' + (props.active === 'rewards' ? 'active' : '')}>
                <span>
                    <img src={rewardsIcon}></img>
                </span>
            </Link>

            <div className="sidebar">
                <div className='sidebar-left'>
                    <Link to="/"  className={props.active == 'home' ? 'active' : ''}>
                        <span>
                            <img src={homeIcon}></img>
                        </span>
                    </Link>

                    <Link to="/settings"  className={props.active == 'settings' ? 'active' : ''}>
                        <span>
                            <img src={settingsIcon}></img>
                        </span>
                    </Link>

                </div>

                <div className='sidebar-right'>
                    <Link to="/letters" className={props.active == 'letters' ? 'active' : ''}>
                        <span>
                            <img src={lettersBlock}></img>
                        </span>
                    </Link>
                    <Link to="/words"  className={props.active == 'words' ? 'active' : ''}>
                        <span>
                            <img src={bookIcon}></img>
                        </span>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default Sidebar