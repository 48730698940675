const rewards = {
    cars: [
        'cars/one.svg',
        'cars/two.svg',
        'cars/three.svg',
        'cars/four.svg',
        'cars/five.svg',
        'cars/six.svg',
        'cars/seven.svg',
        'cars/eight.svg',
        'cars/nine.svg',
        'cars/ten.svg',
        'cars/eleven.svg',
        'cars/twelve.svg',
        'cars/thirteen.svg',
        'cars/fourteen.svg',
        'cars/fifteen.svg',
        'cars/sixteen.svg',
        'cars/seventeen.svg',
        'cars/eighteen.svg',
        'cars/nineteen.svg',
        'cars/twenty.svg',
    ],
    girls: [
        'girls/one.svg',
        'girls/two.svg',
        'girls/three.svg',
        'girls/four.svg',
        'girls/five.svg',
        'girls/six.svg',
        'girls/seven.svg',
        'girls/eight.svg',
        'girls/nine.svg',
        'girls/ten.svg',
        'girls/eleven.svg',
        'girls/twelve.svg',
        'girls/thirteen.svg',
        'girls/fourteen.svg',
        'girls/fifteen.svg',
        'girls/sixteen.svg',
        'girls/seventeen.svg',
        'girls/eighteen.svg',
        'girls/nineteen.svg',
        'girls/twenty.svg',
    ],
    dinos: [
        'dinos/one.svg',
        'dinos/two.svg',
        'dinos/three.svg',
        'dinos/four.svg',
        'dinos/five.svg',
        'dinos/six.svg',
        'dinos/seven.svg',
        'dinos/eight.svg',
        'dinos/nine.svg',
        'dinos/ten.svg',
        'dinos/eleven.svg',
        'dinos/twelve.svg',
        'dinos/thirteen.svg',
        'dinos/fourteen.svg',
        'dinos/fifteen.svg',
        'dinos/sixteen.svg',
        'dinos/seventeen.svg',
        'dinos/eighteen.svg',
        'dinos/nineteen.svg',
        'dinos/twenty.svg',
    ],
    toys: [
        'toys/one.svg',
        'toys/two.svg',
        'toys/three.svg',
        'toys/four.svg',
        'toys/five.svg',
        'toys/six.svg',
        'toys/seven.svg',
        'toys/eight.svg',
        'toys/nine.svg',
        'toys/ten.svg',
        'toys/eleven.svg',
        'toys/twelve.svg',
        'toys/thirteen.svg',
        'toys/fourteen.svg',
        'toys/fifteen.svg',
        'toys/sixteen.svg',
        'toys/seventeen.svg',
        'toys/eighteen.svg',
        'toys/nineteen.svg',
        'toys/twenty.svg',
    ]
}

export { rewards };