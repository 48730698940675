import React from 'react'
import './custompopup.css'

import closeIcon from '../images/close-icon.svg'

function CustomPopup(props) {
  return (props.trigger) ? (
    <div className={'popup' + ( props.className ? ' ' + props.className : '' )}>
        <div className="popup-inner">

        {typeof props.showClose == 'undefined' || props.showClose ? <>
          <button id="mypopup" className="popupElementButton close-btn" onClick={() => props.setTrigger(false)}>      
            <img src={closeIcon} alt="Close Popup" />
          </button>
        </> : <></> }
          {props.children}
        </div>
      
    </div>
  ):"";
}

export default CustomPopup
