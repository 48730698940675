import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import RewardsHeading from '../components/images/rewards-heading.svg'

import CustomPopup from "./Popup/CustomPopup";

function Rewards() {

    const [earnedRewards, setEarnedRewards] = useState(localStorage.getItem('earnedRewards') || '');

    return (
        <div>
            <Sidebar
                active="settings"
            />

            <div className="content">
                <div className="settings-wrapper">

                    <div className="settings-inner">

                        <div className='settings-page-title'>
                            <img src={RewardsHeading} alt="Rewards" />
                        </div>

                        <div className="rewards-list-wrapper">
                            {earnedRewards ? earnedRewards.split(',').map((reward, index) => <>

                                <div className="rewards-item">
                                    <div className="rewards-item-inner">
                                        <img src={process.env.REACT_APP_API_BASE_URL+'/uploads/'+reward} alt="Reward" />
                                    </div>
                                </div>
                            </>) : <>
                                <div className="no-rewards-message">No rewards yet</div>
                            </>}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Rewards;