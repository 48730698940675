import React, {useState, useRef, useEffect } from 'react';
import imageIcon from '../components/images/image-icon.png'
import speakerIcon from '../components/images/sound-icon.png'
import backArrow from '../components/images/back-arrow.svg'
import nextArrow from '../components/images/next-arrow.svg'
import quizTitle from '../components/images/quiz-time-title.svg'
import Sidebar from './Sidebar';
import Results from './Results';
import SlideQuestionnaire from './SlideQuestionnaire';
import {shuffleArray, alphabetLetters, generateRandomColor, randomizeBackground, getRandomInteger} from './Helpers';

import { Link } from 'react-router-dom';
import homeIcon from '../components/images/home-icon.svg';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import CustomPopup from "./Popup/CustomPopup";
import axios from 'axios';

function Letters() {

    const [quizInterval, setQuizInterval] = useState(parseInt( localStorage.getItem('quizInterval') ) || 5 );
    const slides = [];

    // for each qSlides item add a property order
    alphabetLetters.forEach((item, index) => item.order = getRandomInteger());

    for (let i = 0; i < alphabetLetters.length; i += quizInterval) {
        slides.push(alphabetLetters.slice(i, i + quizInterval));
    }

    const qSlides = [];

    for (let i = 0; i < slides.length; i ++) {
        qSlides.push( shuffleArray( [ ...slides[i] ] ) );
    }

    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState( Array( alphabetLetters.length ).fill( null ) );
    const [quizSlides, setQuizSlides] = useState( qSlides );

    const quizSlidesRef = useRef([]);
    const learnSlidesRef = useRef([]);

    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [wrongAnswers, setWrongAnswers] = useState(0);
    const [quizCurrentSlide, setQuizCurrentSlide] = useState(1);
    const [showQuiz, setShowQuiz] = useState(false);
    const [showResultsScreen, setShowResultsScreen] = useState(false);
    const [showTestButton, setShowTestButton] = useState(false);

    const [disableGradients, setDisableGradients] = useState(localStorage.getItem('disableGradients') || '0');

    const [showLightbox, setShowLightbox] = useState(true);
    const [popupImage, setPopupImage] = useState(null);

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        arrows: true,
        slidesToScroll: 1,
        variableWidth: false, // To make each slide take the width of its contents,
        afterChange: ( currentSlide ) => {
            if (currentSlide == learnSlidesRef.current[currentSlideIndex].props.children.length - 1 ) {
                setShowTestButton(true)
            } else {
                setShowTestButton(false)
            }
        }
    };

    const quizSliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        draggable: false,
        arrows: false,
        swipe: false,
        slidesToScroll: 1,
        variableWidth: false, // To make each slide take the width of its contents
        beforeChange: ( currentSlide, nextSlide ) => {
            setQuizCurrentSlide( nextSlide + 1 );
        }
    };

    const playSound = (soundPath) => {
        const audio = new Audio(soundPath);
        audio.play();
    };

    const handleAnswer = (answer, slideIndex, letter) => {
        const updatedAnswers = [...userAnswers];
        updatedAnswers[slideIndex] = answer;
        setUserAnswers(updatedAnswers);
    };

    const checkAnswer = ( slideIndex, index, letter ) => {

        const updatedSlides = [...quizSlides]; // Create a copy of the quizSlides array
        updatedSlides[index][slideIndex].showAnswer = true; // Update the showAnswer property of the slide at the specified index
        setQuizSlides(updatedSlides); // Update the state with the modified array

        var totalCorrect = 0;
        var totalWrong = 0;

        quizSlides.map((qSlide, qIndex) => {
            qSlide.map((slide, index) => {
                if ( qIndex == currentSlideIndex ) {
                    if (userAnswers[index] == slide.letter) {
                        totalCorrect++;
                    } else {
                        totalWrong++;
                    }
                }
            })
        });

        if ( userAnswers[slideIndex] != letter ) {
            playSound( process.env.REACT_APP_API_BASE_URL + '/mp3s/effects/fail.mp3' );
        } else {
            playSound( process.env.REACT_APP_API_BASE_URL + '/mp3s/effects/success.mp3' );
        }

        setCorrectAnswers(totalCorrect);
        setWrongAnswers(totalWrong);
    }

    const quizSlideNext = () => {
        const currentSlider = quizSlidesRef.current[currentSlideIndex];

        if (currentSlider) {
            const totalSlides = currentSlider.props.children.length;

            // Check if it's the last slide (using option 1)
            if (quizCurrentSlide === totalSlides) {
              // Last slide reached, show results screen
              setShowResultsScreen(true);
            } else {
              currentSlider.slickNext();
            }
          }
    }

    const moveNextSection = () => {
        setShowQuiz(false);
        setShowResultsScreen(false);
        setCurrentSlideIndex(currentSlideIndex + 1);
        setQuizCurrentSlide(1);
        setCorrectAnswers(0); // Reset correct answers count
        setWrongAnswers(0); // Reset wrong answers count

        let resetQSlides = [...quizSlides];
        resetQSlides.map((qSlide, qIndex) => {
            qSlide.showAnswer = false;
        });
        // update quizSlides
        setQuizSlides(resetQSlides);
        setShowTestButton(false);
    }

    const moveBackSection = () => {

        setShowResultsScreen(false);
        setShowQuiz(false);
        setQuizCurrentSlide(1);
        setWrongAnswers(0);
        setCorrectAnswers(0);
    }

    const viewImage = (imagePath) => {
        setShowLightbox(false);
        setPopupImage(imagePath);
        // window.open(imagePath, '_blank');
    };

    async function speak( letter, phonic ) {

        // /api/speechifyword
        const url = process.env.REACT_APP_API_BASE_URL;

        try {
            const response = await axios.post( `${url}/api/speechifyletter`, { letter, phonic } );
            let audioUrl = process.env.REACT_APP_API_BASE_URL + '/mp3s' +response.data.split( '/mp3s' )[1];
            playSound(audioUrl);
        } catch (error) {
            // Do nothing for now.
        }
    }

    // listen changes to quizSlides
    useEffect(() => {
        var totalCorrect = 0;
        var totalWrong = 0;

        quizSlides.map((qSlide, qIndex) => {
            qSlide.map((slide, index) => {
                if ( qIndex == currentSlideIndex ) {
                    if (userAnswers[index] == slide.letter) {
                        totalCorrect++;
                    } else {
                        totalWrong++;
                    }
                }
            })
        })

        setCorrectAnswers(totalCorrect);
        setWrongAnswers(totalWrong);
    }, [quizSlides])

    useEffect(() => {
        randomizeBackground();
    }, [showQuiz])

    return (
        <div>
            <Sidebar
                active="letters"
            />

            <CustomPopup
                trigger={!showLightbox}
                setTrigger={() => {
                    setShowLightbox(true);
                }}
            >
                {popupImage && <>
                    <img className="popup-image" src={popupImage}></img>
                </>}
            </CustomPopup>

            <div className="content">

                {showTestButton && !showResultsScreen && !showQuiz && <>
                    <button
                        className='start-test-btn'
                        onClick={() => setShowQuiz(true)}
                    >
                        Test Now
                    </button>
                </>}

                {!showResultsScreen && <>
                    {slides.map((slide, index) => (
                        <div
                            className='quiz-slider letters-quiz-slider'
                            key={index} style={{ display: index === currentSlideIndex && ! showQuiz ? 'block' : 'none' }}
                        >
                            <Slider
                                {...sliderSettings}
                                ref={sliderRef => learnSlidesRef.current[index] = sliderRef}
                            >
                                {slide.map(({ letter, sound, image, phonic }) => (
                                    <div key={letter} className="letter-item is-letter-slide-item">
                                        <h3 className="quiz-slide-heading" style={
                                            disableGradients != 1 ? {
                                                background: `-webkit-linear-gradient(90deg, ` + generateRandomColor() + ` 0%, ` + generateRandomColor() + ` 100%)`
                                            } : {}
                                        }>
                                            {letter}{letter.toLowerCase()}
                                        </h3>
                                        <div className="slide-actions">
                                            <div className='slide-action slide-icon-speaker' onClick={() => {sound ? playSound(sound) : speak(letter, phonic) }}>
                                                <img className='play-icon' src={speakerIcon} alt="Play Icon"></img>
                                            </div>
                                            <div className='slide-action slide-icon-image' onClick={() => viewImage(image)}>
                                                <img className='view-icon' src={imageIcon} alt="Image Icon" onClick={() => viewImage(image)}></img>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    ))}

                    {quizSlides.map((slide, index) => (
                        <div className='quiz-slider is-quiz-slider' key={'s' + index} style={{ display: index === currentSlideIndex && showQuiz ? 'block' : 'none' }}>
                            <div className="quiz-slider-index">
                                <span>{quizCurrentSlide}</span> / <span>{slide.length}</span>
                            </div>
                            <div className='quizSliderWrapper'>
                                <div className="quiz-time-title">
                                    <img src={quizTitle} alt={"Quiz Time"} />
                                </div>
                                <Slider {...quizSliderSettings} ref={sliderRef => quizSlidesRef.current[index] = sliderRef}>
                                    {slide.map(({ letter, sound, image, phonic }, slideIndex) => (
                                        <div className={quizSlides[index][slideIndex]?.showAnswer ? 'quiz-slide-single quiz-slide-show-answer' : 'quiz-slide-single'}>
                                            <div className='slide-action slide-icon-speaker quiz-play-icon' onClick={() => {sound ? playSound(sound) : speak(letter, phonic) }}>
                                                <img className='play-icon' src={speakerIcon} alt="Icon"></img>
                                            </div>

                                            <h4>Choose Correct Answer Below:</h4>
                                            <div className={ 'answer-options ' + (userAnswers[slideIndex] != letter ? 'has-wrong-answer' : 'has-correct-answer') }>
                                                <SlideQuestionnaire
                                                    slide={slide}
                                                    type="letter"
                                                    slideIndex={slideIndex}
                                                    userAnswers={userAnswers}
                                                    handleAnswer={handleAnswer}
                                                    letter={letter}
                                                />

                                            </div>

                                            {quizSlides[index][slideIndex]?.showAnswer ? <>
                                                {userAnswers[slideIndex] != letter ? <>
                                                    <div className='wrong-answer'>
                                                        <span>Sorry! Your Answer is Wrong.</span>
                                                        <button className='light-blue-btn' onClick={() => {
                                                            
                                                            // set letter to empty for slideIndex in userAnswers
                                                            const updatedAnswers = [...userAnswers];
                                                            quizSlides[index].map((slide, index) => updatedAnswers[index] = '');
                                                            setUserAnswers(updatedAnswers);

                                                            // set showAnswer to false
                                                            const updatedSlides = [...quizSlides];
                                                            // updatedSlides[index][slideIndex].showAnswer = false;
                                                            updatedSlides[index].map((slide, index) => slide.showAnswer = false);
                                                            setQuizSlides(updatedSlides);

                                                            // Move sliderRef to first slide
                                                            learnSlidesRef.current[index].slickGoTo(0);
                                                            quizSlidesRef.current[index].slickGoTo(0);
                                                            
                                                            setShowQuiz(false);
                                                        }}><img src={backArrow}></img><span>Go Back</span></button>
                                                    </div>
                                                </> : <>
                                                    <div className='success-answer'>
                                                        <span>Thank you, Your Answer is Correct.</span>
                                                        <button className='light-blue-btn' onClick={() => quizSlideNext()}><span>Next</span><img src={nextArrow}></img></button>
                                                    </div>
                                                </>}
                                            </> : <>
                                                <button onClick={() => checkAnswer(slideIndex, index, letter) } className="check-answer-btn">Check Answer</button>
                                            </>}

                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    ))}
                </>}

                {currentSlideIndex == quizSlides.length && <>
                    <div className='no-more-questions'>
                        Congratulations!<br />You have passed all the questions.
                        <br /><small><small>Press Following button to go to home</small></small>
                        <div className='go-to-home'>
                            <Link to="/">
                                <span>
                                    <img src={homeIcon}></img>
                                </span>
                            </Link>
                        </div>
                    </div>
                </>}

                {showResultsScreen && <>
                    <Results
                        correctAnswers={correctAnswers}
                        wrongAnswers={wrongAnswers}
                        moveNextSection={moveNextSection}
                        moveBackSection={moveBackSection}
                    />
                </>}

            </div>
        </div>
    );
}

export default Letters;