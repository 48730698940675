import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import lettersBlock from '../components/images/alphabets-icon.png'
import bookIcon from '../components/images/words-icon.svg'
import settingsIcon from '../components/images/settings-icon.svg'

function Home() {
  return (
    <div className='mainWrapper'>
        <section id="boxes">
            <div className="container">
                <div className='home-blocks'>
                  <div className="box">
                    <Link to="/letters" className='home-block letters-block'>
                      <span>
                        <img src={lettersBlock}></img>
                      </span>
                      <span>
                        Letters
                      </span>
                    </Link>
                  </div>
                  <div className="box">
                    <Link to="/words" className='home-block book-block'>
                      <span>
                        <img src={bookIcon}></img>
                      </span>
                      <span>
                        Words
                      </span>
                    </Link>
                  </div>
                  <div className="box">
                    <Link to="/settings" className='home-block settings-block'>
                      <span>
                        <img src={settingsIcon}></img>
                      </span>
                      <span>
                        Settings
                      </span>
                    </Link>
                  </div>
                </div>
            </div>
        </section>
    </div>
  );
}

export default Home;
