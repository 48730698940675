import React, {useEffect, useState} from 'react';
import {getRandomInteger} from "./Helpers";

function SlideQuestionnaire( props ) {

     const [randomOrders, setRandomOrders] = useState([]);

    // Generate random order values when the component mounts
    useEffect(() => {
        const orders = props.slide.map(() => getRandomInteger());
        setRandomOrders(orders);
    }, [props.slide]);

    return (
        <>
            {props.slide.map( ({ letter: letterInner, word: wordInner }, index) => (
                <div key={props.type === 'letter' ?letterInner:wordInner} style={{ order: randomOrders[index] }}>
                    <label>
                        <input
                            type="radio"
                            name={`answer-${props.slideIndex}`}
                            value={props.type === 'letter' ?letterInner:wordInner}
                            checked={props.userAnswers[props.slideIndex] === ( props.type === 'letter' ?letterInner:wordInner )}
                            onChange={() => props.handleAnswer((props.type === 'letter' ?letterInner:wordInner), props.slideIndex, props.letter)}
                        />
                        <span>{props.type === 'letter' ?letterInner:wordInner}</span>
                    </label>
                </div>
            ))}
        </>
    );
}

export default SlideQuestionnaire