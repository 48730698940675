import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import congratulations from '../components/images/congratulations-title.svg'
import stars from '../components/images/stars.png'
import redCross from '../components/images/red-cross.svg'
import leftArrow from '../components/images/left-blue-arrow.svg'
import homeIcon from '../components/images/home-icon.svg'
import blueNext from '../components/images/blue-next.svg'
import rewardsImage from '../components/images/rewards-icon.png'
import closeIcon from '../components/images/close-icon.svg'
import {rewards} from "./RewardsConfig";

function Results(props) {

    const [showReward, setShowReward] = useState( false );
    const [earnedRewards, setEarnedRewards] = useState(localStorage.getItem('earnedRewards') || '');
    const [rewardType, setRewardType] = useState(localStorage.getItem('rewardType') || 'toys');
    const [playedSounds, setPlayedSounds] = useState( false );
    const [newRewardEarned, setNewRewardEarned] = useState('');

    useEffect(() => {
        if ( showReward ) {
            let earnableRewards = rewards[rewardType];

            let newRewards = earnedRewards.split(',');

            // find elements from earnableRewards that are not in newRewards
            let newReward = earnableRewards.filter(x => !newRewards.includes(x));

            // Find a random item from newReward
            if ( newReward.length > 0 ) {
                let randomReward = newReward[Math.floor(Math.random() * newReward.length)];
                setEarnedRewards(earnedRewards + ',' + randomReward);
                localStorage.setItem('earnedRewards', (earnedRewards + ',' + randomReward).replace(/^,/, '') );

                setNewRewardEarned(randomReward);
            }

        }
    }, [showReward]);

    const playSound = (soundPath) => {
        if ( playedSounds ) {
            return;
        }
        const audio = new Audio(soundPath);
        audio.play();

        setPlayedSounds( true );
    };

    if ( props.correctAnswers > props.wrongAnswers ) {
        // Success
        playSound( process.env.REACT_APP_API_BASE_URL + '/mp3s/effects/quiz-success.mp3' );
    } else {
        // Fail
        playSound( process.env.REACT_APP_API_BASE_URL + '/mp3s/effects/quiz-fail.mp3' );
    }

    return (
        <div className={"results-message" + ( showReward ? ' show-reward' : '' )}>
            { showReward ? <>
                <div className="rewards-wrapper">
                    <div className="rewards-close" onClick={() => props.moveNextSection()}>
                        <img src={closeIcon} alt="Close" />
                    </div>

                    <div className="rewards-icon-wrapper">

                        {newRewardEarned ? <div className='earned-reward-icon'>
                            <img src={process.env.REACT_APP_API_BASE_URL+'/uploads/'+newRewardEarned} alt="Reward" />
                        </div> : ''}

                        <img src={rewardsImage} alt="Rewards!" />
                    </div>
                </div>
            </> : <>
                {props.correctAnswers > props.wrongAnswers ? <>
                    <div className="congratulations-message">
                        <div className="congratulations-message-title">
                            <img src={congratulations} alt="Congratulations" />
                        </div>

                        <div className="congratulations-stars">
                            <img src={stars} alt="Stars"/>
                        </div>

                        <div className="congratulations-stars-title">Mission Complete</div>

                    </div>
                </> : <>
                    <div className="unsuccessful-message">
                        <img src={redCross}></img>
                        <h2>Error!</h2>
                        <p>Your test was unsuccessful.</p>
                    </div>
                </>}

                {/*<div className='reults-message-counters'>*/}
                {/*    <div><span>Correct Answers:</span> <span className="green-text">0{props.correctAnswers}</span></div>*/}
                {/*    <div><span>Wrong Answers:</span> <span className="red-text">0{props.wrongAnswers}</span></div>*/}
                {/*</div>*/}

                {props.correctAnswers > props.wrongAnswers ? <>
                    <div className='results-move-next'>
                        <Link to="/" className='home-btn'><span><img src={homeIcon}></img></span></Link>
                        <button className="next-btn" onClick={() => setShowReward(true)}>
                            <img src={blueNext} alt="Next" />
                        </button>
                    </div>
                </> : <>
                    <div className='results-move-back'>
                        <button className='light-blue-btn' onClick={() => props.moveBackSection()}><img src={leftArrow}></img><span>Go Back</span></button>
                    </div>
                </>}
            </> }


        </div>
    );
}

export default Results;